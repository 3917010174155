import useDeviceDetect from '../../../common/hooks/useDeviceDetect'
import Img from '../../shared/Img'
import React from 'react'
import CallToAction from '../../shared/CallToActionButton'
import ButtonLink from '../../shared/ButtonLink'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const StickyButtons = ({
  elementsOnViewport = true,
  isOverHeader,
  handleLogoClick,
  handleButtonClick,
  navBarElement = null,
  phoneNumberText,
}) => {
  const { isMobile } = useDeviceDetect()

  return (
    <>
      {!elementsOnViewport && !isMobile ? (
        <div
          className={classNames(
            'fixed left-0 top-0 z-80 flex w-full items-center justify-center bg-white duration-300',
            isOverHeader ? 'h-20.75' : 'h-[8em] flex-col'
          )}
        >
          {!isOverHeader && navBarElement ? (
            <div className="text-14 text-gray-255">{navBarElement}</div>
          ) : (
            <NextLink
              href="/"
              prefetch={false}
              shallow={true}
              data-testid="logo-link"
              onClick={handleLogoClick}
            >
              <Img
                priority={true}
                dataTestId="fdr-logo"
                src="/next-assets/icons/freedom-logo.svg"
                alt="Freedom Debt Relief"
                className="h-10.5 w-24.75"
                layout="responsive"
              />
            </NextLink>
          )}
          <div className="my-3 flex items-center justify-center">
            <p className="ml-5.5 mr-5 text-14 text-gray-255">
              Get credit card debt relief from America’s top debt relief
              company.
            </p>
            <ButtonLink
              className="mr-5 rounded-lg border-2 border-blue-base px-5 py-2.25 text-16 font-bold text-blue-base"
              href={`tel:${phoneNumberText}`}
              onClick={(href) =>
                handleButtonClick(
                  href,
                  `Call ${phoneNumberText}`,
                  'Floating Menu',
                  false
                )
              }
            >
              Call {phoneNumberText}
            </ButtonLink>
            <CallToAction
              text="See if you qualify"
              bgColor="bg-red-950"
              className="max-w-max rounded-lg px-5 py-2.25"
              fontClassName="font-bold text-white"
              experimentData={{
                experimentSection: 'floatingHeaderFooter',
                variation: 'floating_menu',
              }}
              nav_link_section="Floating Menu"
            />
          </div>
        </div>
      ) : null}

      {!elementsOnViewport && isMobile ? (
        <div className="fixed inset-x-0 bottom-0 z-100 mx-auto flex w-full justify-center bg-white px-6 py-5 drop-shadow">
          <ButtonLink
            className="mr-5 h-max w-max rounded-lg border-2 border-blue-base px-5 py-2.25 text-16 font-bold text-blue-base"
            href={`tel:${phoneNumberText}`}
            onClick={(href) =>
              handleButtonClick(href, 'Call Us', 'Floating Menu', false)
            }
          >
            Call Us
          </ButtonLink>
          <CallToAction
            text="See if you qualify"
            bgColor="bg-red-950"
            className="max-w-52.25 rounded-lg px-5 py-2.25"
            fontClassName="font-bold text-white"
            experimentData={{
              experimentSection: 'floatingHeaderFooter',
              variation: 'floating_menu',
            }}
            nav_link_section="Floating Menu"
          />
        </div>
      ) : null}
    </>
  )
}

StickyButtons.propTypes = {
  elementsOnViewport: PropTypes.bool,
  isHomeVariation: PropTypes.bool,
  isOverHeader: PropTypes.bool,
  handleLogoClick: PropTypes.func,
  handleButtonClick: PropTypes.func,
}
export default StickyButtons
